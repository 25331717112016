import React from 'react';
import { Button, Typography, TextField, MenuItem } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import pageStyles from './ReferralDetails.module.scss';
import { useNavigate } from 'react-router-dom';
import RouteConfig from '../resources/route_config';

export default function ReferralDetails(props) {
  const [referralDetails, setReferralDetails] = React.useState({
    ...props.referral,
  });
  const navigate = useNavigate();

  const isExistingReferral =
    props.referral && Object.keys(props.referral).length > 0;

  // TODO: get these from the database
  const statusOptions = ['New', 'Accepted', 'Scheduled', 'Follow-up', 'Closed'];
  const priorityOptions = ['Standard', 'Low', 'High'];
  const relationshipOptions = [
    'Spouse',
    'Parent',
    'Child',
    'Grandparent',
    'Other',
  ];

  function submitHandler(navigate) {
    console.log(referralDetails);
    alert('Adding / updating the referral. Placeholder for now.');
  }

  function resetHandler() {
    setReferralDetails({ ...props.referral });
  }

  function cancelHandler(navigate) {
    navigate(RouteConfig.home);
  }

  function scheduleHandler() {
    console.log('Going to schedule an appointment.');
    alert('Schedule and appointment. Placeholder for now.');
  }

  function inputChangedHandler(field, value) {
    setReferralDetails((prevState) => {
      const newState = { ...prevState };
      newState[field] = value;
      return newState;
    });
  }

  return (
    <div className={pageStyles.referralDetails}>
      <TextField
        variant='outlined'
        label='Referral number'
        placeholder='Referral number'
        value={referralDetails['referralNumber'] || ''}
        onChange={(event) => {
          inputChangedHandler('referralNumber', event.target.value);
        }}
      />
      <TextField
        variant='outlined'
        label='Phone number'
        placeholder='Phone number'
        value={referralDetails['phoneNumber'] || ''}
        onChange={(event) => {
          inputChangedHandler('phoneNumber', event.target.value);
        }}
      />
      <TextField
        variant='outlined'
        label='Veteran ID (ICN)'
        placeholder='Veteran ID (ICN)'
        value={referralDetails['veteranID'] || ''}
        onChange={(event) => {
          inputChangedHandler('veteranID', event.target.value);
        }}
      />
      <TextField
        variant='outlined'
        label='Email'
        placeholder='Email'
        value={referralDetails['email'] || ''}
        onChange={(event) => {
          inputChangedHandler('email', event.target.value);
        }}
      />
      <TextField
        variant='outlined'
        label='Veteran name'
        placeholder='Veteran name'
        value={referralDetails['veteranName'] || ''}
        onChange={(event) => {
          inputChangedHandler('veteranName', event.target.value);
        }}
      />
      <TextField
        variant='outlined'
        label='Address'
        placeholder='Address'
        value={referralDetails['address'] || ''}
        onChange={(event) => {
          inputChangedHandler('address', event.target.value);
        }}
      />
      <TextField
        select
        variant='outlined'
        label='Status'
        placeholder='New'
        value={referralDetails['referralStatus'] || statusOptions[0]}
        onChange={(event) => {
          inputChangedHandler('referralStatus', event.target.value);
        }}
      >
        {statusOptions.map((item) => (
          <MenuItem value={item} key={item}>
            <Typography textTransform={'none'}>{item}</Typography>
          </MenuItem>
        ))}
      </TextField>
      <TextField
        variant='outlined'
        label='Proxy name'
        placeholder='Proxy name'
        value={referralDetails['proxyName'] || ''}
        onChange={(event) => {
          inputChangedHandler('proxyName', event.target.value);
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label='Referral date'
          value={referralDetails['referralDate'] || dayjs(Date.now())}
          onChange={(event) => {
            console.log(event);
            inputChangedHandler('referralDate', dayjs(event.d));
          }}
        />
      </LocalizationProvider>
      <TextField
        variant='outlined'
        select
        label='Proxy relationship'
        placeholder='Proxy relationship'
        value={referralDetails['proxyRelationship'] || ''}
        onChange={(event) => {
          inputChangedHandler('proxyRelationship', event.target.value);
        }}
      >
        {relationshipOptions.map((item) => (
          <MenuItem value={item} key={item}>
            <Typography textTransform={'none'}>{item}</Typography>
          </MenuItem>
        ))}
      </TextField>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label='Referral expiration'
          value={referralDetails['referralExpiration'] || dayjs(Date.now())}
          onChange={(event) => {
            console.log(event);
            inputChangedHandler('referralExpiration', dayjs(event.d));
          }}
        />
      </LocalizationProvider>
      <TextField
        variant='outlined'
        label='Proxy email / phone / address'
        placeholder='Proxy email / phone / address'
        value={referralDetails['proxyContact'] || ''}
        onChange={(event) => {
          inputChangedHandler('proxyContact', event.target.value);
        }}
      />
      <TextField
        select
        variant='outlined'
        label='Priority'
        placeholder='Priority'
        value={referralDetails['priority'] || priorityOptions[0]}
        onChange={(event) => {
          inputChangedHandler('priority', event.target.value);
        }}
      >
        {priorityOptions.map((item) => (
          <MenuItem value={item} key={item}>
            <Typography textTransform={'none'}>{item}</Typography>
          </MenuItem>
        ))}
      </TextField>
      {isExistingReferral && (
        <Button
          className={`${pageStyles.button} ${pageStyles.scheduleButton}`}
          variant='contained'
          onClick={scheduleHandler}
        >
          <Typography textTransform={'none'}>Schedule</Typography>
        </Button>
      )}
      <Button
        className={`${pageStyles.button} ${pageStyles.cancelButton}`}
        variant='contained'
        onClick={() => {
          cancelHandler(navigate);
        }}
      >
        <Typography textTransform={'none'}>Cancel</Typography>
      </Button>
      <Button
        className={`${pageStyles.button} ${pageStyles.resetButton}`}
        variant='contained'
        onClick={resetHandler}
      >
        <Typography textTransform={'none'}>Reset</Typography>
      </Button>

      <Button
        className={`${pageStyles.button} ${pageStyles.addButton}`}
        variant='contained'
        onClick={() => {
          submitHandler(navigate);
        }}
      >
        <Typography textTransform={'none'}>
          {isExistingReferral ? 'Update' : 'Add'}
        </Typography>
      </Button>
    </div>
  );
}
