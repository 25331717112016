import { createRef, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { ReactComponent as SearchIcon } from '../assets/magnifying-glass.svg';
import componentStyles from './ReferralSearchCard.module.scss';

const searchOnEnterKeyPress = (e) => {
  if (e.key === 'Enter') {
    this.setState({ searchText: e.target.value }, () => {
      this.searchButtonRef.current.click();
    });
  }
};

export default function ReferralSearchCard() {
  const [searchText, setSearchText] = useState('');
  const searchButtonRef = createRef();
  const searchTextFieldRef = createRef();
  const handleSearchClick = () => {
    alert(`Search for "${searchText}" not yet implemented`);
  };

  return (
    <Card className={componentStyles.card}>
      <CardContent className={componentStyles.cardContainer}>
        <div>
          <Typography sx={{ fontWeight: 600, fontSize: 18 }}>
            Search for a Referral by Veteran ID, Name, or Referral ID
          </Typography>
        </div>
        <div className={componentStyles.searchContainer}>
          <div className={componentStyles.searchFieldsContainer}>
            <TextField
              inputRef={searchTextFieldRef}
              placeholder='Find by Referral ID'
              className={componentStyles.searchBox}
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon className={componentStyles.searchIcon} />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '4px',
                  height: '40px',
                  fontStyle: 'italic',
                },
              }}
              onKeyDown={searchOnEnterKeyPress}
              onBlur={(v) => setSearchText(v.target.value)}
            />
            <TextField
              inputRef={searchTextFieldRef}
              placeholder='Find by Veteran ID'
              className={componentStyles.searchBox}
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon className={componentStyles.searchIcon} />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '4px',
                  height: '40px',
                  fontStyle: 'italic',
                },
              }}
              onKeyDown={searchOnEnterKeyPress}
              onBlur={(v) => setSearchText(v.target.value)}
            />
            <TextField
              inputRef={searchTextFieldRef}
              placeholder='Find by Veteran Name'
              className={componentStyles.searchBox}
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon className={componentStyles.searchIcon} />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '4px',
                  height: '40px',
                  fontStyle: 'italic',
                },
              }}
              onKeyDown={searchOnEnterKeyPress}
              onBlur={(v) => setSearchText(v.target.value)}
            />
          </div>
          <div>
            <Button
              disableRipple
              ref={searchButtonRef}
              className={componentStyles.searchButton}
              variant='contained'
              // startIcon={<SearchIcon />}
              onClick={() => {
                handleSearchClick();
              }}
            >
              Find Referral
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
