import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import componentStyles from './ReferralsTable.module.scss';

function getTitleCaseString(inputString) {
    const PascalCased =
        inputString.charAt(0).toUpperCase() + inputString.slice(1);
    let result = PascalCased.split(/(?=[A-Z])/).join(' ');
    return result;
}

function getTableRow(referralRecord, index) {
    console.log(referralRecord);
    return Object.keys(referralRecord).map((propName) => {
        let displayText = referralRecord[propName];
        return (
            <TableCell
                key={`${propName}-${index}`}
                className={componentStyles.tableCell}
            >
                <Typography>{displayText}</Typography>
            </TableCell>
        );
    });
}

const ReferralsTable = (referrals) => {
    const columns = Object.keys(referrals.referralData[0]);
    return (
        <TableContainer className={componentStyles.tableContainer}>
            <Table
                stickyHeader
                className={componentStyles.lastColumnPadding}
                aria-label="sticky table"
            >
                <TableHead>
                    <TableRow>
                        {columns.map((columnName) => (
                            <TableCell
                                key={columnName}
                                className={componentStyles.tableHeaderText}
                            >
                                {getTitleCaseString(columnName)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {referrals.referralData.map((rowData, index) => (
                        <TableRow key={`row-${index}`}>
                            {getTableRow(rowData, index)}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ReferralsTable;
