import { Button, Typography } from '@mui/material';
import pageStyles from './ReferralsHub.module.scss';
import ReferralSearchCard from '../components/ReferralSearchCard';
import healthTestAccounts from '../health_test_accounts.json';
import ReferralsTable from '../components/ReferralsTable';
import { useNavigate } from 'react-router-dom';
import RouteConfig from '../resources/route_config';

const handleAddReferralClick = (navigate) => {
  navigate(RouteConfig.details);
};

const handleReferralSearchClick = () => {
  alert('This is a stub for finding unscheduled referrals');
};

const produceDummyReferrals = () => {
  const addMonthToDate = (dateString) => {
    if (dateString.length === 0) {
      return '';
    }
    const baseDate = new Date(dateString);
    const baseMonth = baseDate.getMonth();
    baseDate.setMonth(baseMonth + 3);

    const newYear = baseDate.toLocaleString('default', { year: 'numeric' });
    const newMonth = baseDate.toLocaleString('default', { month: '2-digit' });
    const newDay = baseDate.toLocaleString('default', { day: '2-digit' });
    const result = `${newYear}-${newMonth}-${newDay}`;
    return result;
  };

  const referrals = [];
  const loginList = healthTestAccounts.testAccounts;
  loginList.forEach((testAccount) => {
    // need referral #, veteran id, veteran name, status, exp date, referral date
    let vetReferral = {
      referralNumber: testAccount.ICN.split('').reverse().join(''),
      veteranId: testAccount.ICN,
      name: `${testAccount.FirstName} ${testAccount.LastName}`,
      status: 'in process',
      expirationDate: addMonthToDate(testAccount.Birthdate),
      referralDate: testAccount.Birthdate,
    };

    referrals.push(vetReferral);
  });

  return referrals;
};

export default function ReferralsHub() {
  const navigate = useNavigate();
  const referrals = produceDummyReferrals();
  console.log(referrals);
  console.log(referrals[0]);

  return (
    <div className={pageStyles.referralHub}>
      <div className={pageStyles.buttonsContainer}>
        <Button
          variant='outlined'
          className={pageStyles.button}
          onClick={() => {
            handleAddReferralClick(navigate);
          }}
        >
          <Typography textTransform={'none'}>Add Referral</Typography>
        </Button>
        <Button
          variant='outlined'
          className={pageStyles.button}
          onClick={handleReferralSearchClick}
        >
          <Typography textTransform={'none'}>
            Find Unscheduled Referrals
          </Typography>
        </Button>
      </div>
      <div className={pageStyles.referrals}>
        <ReferralSearchCard />
        <ReferralsTable referralData={referrals} />
      </div>
    </div>
  );
}
