import { Typography } from '@mui/material';
import { ReactComponent as CircleUserIcon } from '../assets/circle-user-regular.svg';
import headerStyles from './Header.module.scss';

export default function Header() {
    return (
        <header className={headerStyles.header}>
            <div className={headerStyles.appSignature}>
                <Typography
                    fontWeight={600}
                    fontSize={24}
                    color={'white'}
                    whiteSpace={'nowrap'}
                >
                    Rural Health Referral Manager
                </Typography>
            </div>
            <div>
                <CircleUserIcon
                    className={headerStyles.iconStyle}
                    alt="user profile"
                    width={30}
                />
            </div>
        </header>
    );
}
