import './pages/ReferralsHub';
import React from 'react';
import pageStyles from './App.css';
import Header from './components/Header';
import { Routes, Route } from 'react-router-dom';
import ReferralsHub from './pages/ReferralsHub';
import ReferralDetails from './pages/ReferralDetails';
import RouteConfig from './resources/route_config';

function App() {
  const [selectedReferral, setSelectedReferral] = React.useState({
    //referralNumber: 'R12345',
  });
  return (
    <div className={pageStyles.main}>
      <Header />
      <Routes>
        {/* TODO: set selectedReferral and onReferralSelect props in ReferralsHub, and store in state. Use in ReferralDetails */}
        <Route path={RouteConfig.home} element={<ReferralsHub />} />
        <Route
          path={RouteConfig.details}
          element={<ReferralDetails referral={selectedReferral} />}
        />
      </Routes>
    </div>
  );
}

export default App;
